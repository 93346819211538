/* NWZ tenant styles */

@import "../colors";
@import "../settings";

.kmta__tenant--nwz {
    .nwz__logo {
        position: relative;
        margin-right: 48px;
    }

    .nwz_svgIcon--logo {
        width: 80px;
        height: 80px;
    }

    .kmta__tenantLogoAlt--nwz {
        display: inline-block;
        max-width: 200px;
        margin-bottom: 25px;
    }

    .kmta__headerNavWrapper{
        margin-left: -12px;
        padding-left: 0px;
    }
    .kmta__faqHome {
        background: linear-gradient(
            to bottom,
            rgba(236, 237, 237, 1) 0%,
            rgba(198, 199, 200, 1) 100%
        );
    }

    .dropdown.menu li.active > a,
    .menu.is-dropdown-submenu
        li.is-submenu-item.is-dropdown-submenu-item.active
        > a {
        font-weight: $global-weight-bold;
    }
    @include breakpoint(small down) {
        .kmta__headerTop {
            .kmta__tenantLogoAlt {
                display: none;
            }
            .kmta__headerUserinfo {
                margin-top: 0;
            }
        }
        .kmta__headerBottomRow {
            justify-content: space-between;
            .kmta__menu {
                justify-content: end;
                text-align: right;
            }
        }
    }
}
